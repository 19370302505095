export class Constants {
  public static readonly TM_FRONT_VERSION: string = '0.99';
  public static readonly TM_PROTOCOL: string = 'https';
  //public static readonly TM_HOSTNAME: string = '10.10.9.30';
  //public static readonly TM_HOSTNAME: string = '10.10.9.30';

  public static readonly TM_HOSTNAME: string = 'front.tm.bsf-intranet.org';
  public static readonly TM_PORT: string = '443';

  public static readonly TM_BASE_API: string = '/goto/temps-modernes/api';

  public static readonly TM_API_URL  =  Constants.TM_PROTOCOL + '://' + Constants.TM_HOSTNAME + ':'
    + Constants.TM_PORT + Constants.TM_BASE_API;
  public static readonly TOKEN = 'bsf_tm_token';
  public static readonly OMEKA_ITEM_URL = 'https://omeka.tm.bsf-intranet.org/admin/items/show/';

}
