import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';
import { User } from '../models/user.model'
import { Session, ISession } from '../models/session.model'
import { Constants } from './constants';
import { throwError, Observable } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SessionService {


  constructor(private httpClient: HttpClient) {
  }

  set(session: Session): void {
    sessionStorage.setItem(Constants.TOKEN, JSON.stringify(session));
  }

  get(): Session {
    return <Session>JSON.parse(sessionStorage.getItem(Constants.TOKEN));
  }

  isLogged(): boolean {
    return sessionStorage.getItem(Constants.TOKEN) != null;
  }

  validateSession(token: string) {
    return this.httpClient.get<ISession>(`${Constants.TM_API_URL}/login/session/` + token, { observe: 'response' }).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(err: any) {
    return throwError(err.message || err);
  }



  getLoggedUser(): User {
    return <User>(sessionStorage.getItem(Constants.TOKEN) != null ? this.get().user : null);
  }

  remove(): void {
    sessionStorage.removeItem(Constants.TOKEN);
  }


}
