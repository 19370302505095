import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../models/user.model';
import { LoginService } from '../../services/login.service';
import { Constants } from '../../services/constants';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { SessionService } from '../../services/session.service';

@Component({
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {

  user: User = new User();

  version = Constants.TM_FRONT_VERSION;

  ngOnInit(): void {
    //Netoyage de la session
    this.sessionService.remove();

  }

  constructor(
    private loginService: LoginService,
    private router: Router,
    private sessionService: SessionService) {
  }

  tryLogin(data) {
    if (data.username === undefined || data.password === undefined) {
      window.alert('Username or password are invalid!');
    } else {
      this.loginService.login(data).pipe(
        catchError(this.handleError)
      ).subscribe((user) => {
        user = user;
        if (this.loginService.isLogged) {
          this.router.navigateByUrl('/dashboard');
        }
      }
      );
    }
  }

  forgot(): void {
    window.alert('What a shame!');
  }
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      console.log('client-side', error);
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      console.log('server-side', error);
      // server-side error
      if (error.status === 401) {
        errorMessage = `Login: Invalid credentials!`;

      } else {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
