import { Injectable} from '@angular/core';
import { HttpInterceptor} from '@angular/common/http';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})

export class TokenInterceptorService implements HttpInterceptor {
  constructor(private sessionService: SessionService) {}

  intercept(req: any, next: any) {
    const tokenizedRequest = req.clone({
      setHeaders: {
        'X-Auth-Token': this.sessionService.get() != null ? this.sessionService.get().token : ''
      }
    });
    return next.handle(tokenizedRequest);
  }

}
