import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';
import { User, IUser } from '../models/user.model'
import { Session, ISession } from '../models/session.model'
import { UserService } from '../services/user.service'
import { SessionService } from '../services/session.service'
import { Constants } from './constants';
import { throwError, Observable } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

const TOKEN = 'bsf_tm_token';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'my-auth-token'
    })
  };

  user: User;

  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
    private sessionService: SessionService,
    private router: Router) {
  }

  login(user: User): Observable<ISession> {
    return this.httpClient.post<ISession>(`${Constants.TM_API_URL}/login/`, JSON.stringify(user), this.httpOptions)
    .pipe(
      tap((session) => {
        this.sessionService.set(<ISession>session);
        return <ISession>session;
      } ));
  }

  logout(): void {
    this.sessionService.remove();
    this.router.navigateByUrl('/login');
  }

  isLogged() {
    return sessionStorage.getItem(TOKEN) != null;
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      console.log('client-side', error);
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      console.log('server-side', error);
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

}
