import {Injectable} from '@angular/core';
import { Constants } from './constants';


const TOKEN = 'TOKEN';

@Injectable({
  providedIn: 'root'
})
export class UserService {

}
