
export interface IUser {
  username: string;
  password: string;
}

export class User implements IUser {
  username: string;
  password: string;
}
