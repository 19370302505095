import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { SessionService } from '../../services/session.service';

@Component({
  templateUrl: 'logout.component.html'
})
export class LogoutComponent implements OnInit {

  ngOnInit(): void {
    this.doLogout();
  }

  constructor(private loginService: LoginService, private sessionService: SessionService) {
  }

  doLogout() {
    this.sessionService.remove();
    this.loginService.logout();
  }

}
