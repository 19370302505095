import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-temps-modernes',
  templateUrl: './temps-modernes.component.html',
  styleUrls: ['./temps-modernes.component.scss']
})
export class TempsModernesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
